<template>
  <div class="measurement-detail-container">
    <div class="area-1 bloodpress">
      <div class="measure-icon"></div>
      <div class="count-title">{{ $t('HR001.times') }}</div>
      <div class="count">
        {{
          detail.bloodPressure && detail.bloodPressure.times
            ? detail.bloodPressure.times
            : ''
        }}
      </div>

      <div class="diastolic-title">{{ $t('HR001.systolic') }}</div>
      <div
        class="diastolic-value"
        :class="{
          'normal-color': detail?.bloodPressure?.systolicWarningLevel == 0,
          'warning-color': detail?.bloodPressure?.systolicWarningLevel == 1,
          'abnormal-color': detail?.bloodPressure?.systolicWarningLevel == 2,
        }"
      >
        {{ getSystolicAVG() }}
      </div>
      <div class="diastolic-unit">{{ $t('HR001.bloodPressureUnit') }}</div>

      <div class="systolic-title">{{ $t('HR001.diastolic') }}</div>
      <div
        class="systolic-value"
        :class="{
          'normal-color': detail?.bloodPressure?.diastolicWarningLevel == 0,
          'warning-color': detail?.bloodPressure?.diastolicWarningLevel == 1,
          'abnormal-color': detail?.bloodPressure?.diastolicWarningLevel == 2,
        }"
      >
        {{ getDiastolicAVG() }}
      </div>
      <div class="systolic-unit">{{ $t('HR001.bloodPressureUnit') }}</div>

      <div class="pp-title">{{ $t('HR001.pp') }}</div>
      <div
        class="pp-value"
        :class="{
          'normal-color': detail?.bloodPressure?.ppWarningLevel == 0,
          'warning-color': detail?.bloodPressure?.ppWarningLevel == 1,
          'abnormal-color': detail?.bloodPressure?.ppWarningLevel == 2,
        }"
      >
        {{ getPpAVG() }}
      </div>
      <div class="pp-unit">{{ $t('HR001.bloodPressureUnit') }}</div>

      <div class="pulse-title">{{ $t('HR001.pulse') }}</div>
      <div
        class="pulse-value"
        :class="{
          'normal-color': detail?.bloodPressure?.pulseWarningLevel == 0,
          'warning-color': detail?.bloodPressure?.pulseWarningLevel == 1,
          'abnormal-color': detail?.bloodPressure?.pulseWarningLevel == 2,
        }"
      >
        {{
          detail.bloodPressure && detail.bloodPressure.pulse
            ? Math.round(detail.bloodPressure.pulse)
            : ''
        }}
      </div>
      <div class="pulse-unit">{{ $t('HR001.pulseUnit') }}</div>

      <div class="arr-title">ARR</div>
      <div class="arr-value">
        {{
          detail.bloodPressure && detail.bloodPressure.arr
            ? detail.bloodPressure.arr
            : 0
        }}
        {{ $t('HR001.countUnit') }}
      </div>

      <div class="arr-afib-title">ARR AFib</div>
      <div class="arr-afib-value">
        {{
          detail.bloodPressure && detail.bloodPressure.arrAfib
            ? detail.bloodPressure.arrAfib
            : 0
        }}
        {{ $t('HR001.countUnit') }}
      </div>

      <div class="arr-pc-title">ARR PC</div>
      <div class="arr-pc-value">
        {{
          detail.bloodPressure && detail.bloodPressure.arrPc
            ? detail.bloodPressure.arrPc
            : 0
        }}
        {{ $t('HR001.countUnit') }}
      </div>

      <div class="arr-afib-pc-title">ARR AFib PC</div>
      <div class="arr-afib-pc-value">
        {{
          detail.bloodPressure && detail.bloodPressure.arrAfibPc
            ? detail.bloodPressure.arrAfibPc
            : 0
        }}
        {{ $t('HR001.countUnit') }}
      </div>

      <div
        class="split-line"
        v-if="detail.bloodPressure && detail.bloodPressure.description"
      ></div>
      <div class="comment">
        {{
          detail.bloodPressure && detail.bloodPressure.description
            ? translateDescription(detail.bloodPressure.description)
            : ''
        }}
      </div>
    </div>
    <div class="area-2 bloodGlucose">
      <div class="measure-icon"></div>
      <div class="count-title">{{ $t('HR001.times') }}</div>
      <div class="count">
        {{ detail.glucose && detail.glucose.times ? detail.glucose.times : '' }}
      </div>

      <div class="fasting-title">{{ $t('HR001.fasting') }}</div>
      <div
        class="fasting-value"
        :class="{
          'normal-color': detail?.glucose?.fastingWarningLevel == 0,
          'warning-color': detail?.glucose?.fastingWarningLevel == 1,
          'abnormal-color': detail?.glucose?.fastingWarningLevel == 2,
        }"
      >
        {{ getFastingAVG() }}
      </div>
      <div class="fasting-unit">{{ getGlucoseUnit() }}</div>

      <div class="beforeMeal-title">{{ $t('HR001.beforeMeal') }}</div>
      <div
        class="beforeMeal-value"
        :class="{
          'normal-color': detail?.glucose?.beforeMealWarningLevel == 0,
          'warning-color': detail?.glucose?.beforeMealWarningLevel == 1,
          'abnormal-color': detail?.glucose?.beforeMealWarningLevel == 2,
        }"
      >
        {{ getBeforeMealAVG() }}
      </div>
      <div class="beforeMeal-unit">{{ getGlucoseUnit() }}</div>

      <div class="afterMeal-title">{{ $t('HR001.afterMeal') }}</div>
      <div
        class="afterMeal-value"
        :class="{
          'normal-color': detail?.glucose?.afterMealWarningLevel == 0,
          'warning-color': detail?.glucose?.afterMealWarningLevel == 1,
          'abnormal-color': detail?.glucose?.afterMealWarningLevel == 2,
        }"
      >
        {{ getAfterMealAVG() }}
      </div>
      <div class="afterMeal-unit">{{ getGlucoseUnit() }}</div>

      <div
        class="split-line"
        v-if="detail.glucose && detail.glucose.description"
      ></div>
      <div class="comment">
        {{
          detail.glucose && detail.glucose.description
            ? translateDescription(detail.glucose.description)
            : ''
        }}
      </div>
    </div>
    <div class="area-5 weight">
      <div class="measure-icon"></div>
      <div class="count-title">{{ $t('HR001.times') }}</div>
      <div class="count">
        {{
          detail.bodyWeight && detail.bodyWeight.times
            ? detail.bodyWeight.times
            : ''
        }}
      </div>

      <div class="weight-title">{{ $t('HR001.bodyWeight') }}</div>
      <div class="weight-value normal-color">
        {{
          detail.bodyWeight && detail.bodyWeight.value
            ? roundDec(detail.bodyWeight.value, 2)
            : ''
        }}
      </div>
      <div class="weight-unit">{{ getWeightUnit() }}</div>

      <div class="bmi-title">{{ $t('HR001.bmi') }}</div>
      <div
        class="bmi-value"
        :class="{
          'normal-color': detail?.bodyWeight?.bmiWarningLevel == 0,
          'warning-color': detail?.bodyWeight?.bmiWarningLevel == 1,
          'abnormal-color': detail?.bodyWeight?.bmiWarningLevel == 2,
        }"
      >
        {{
          detail.bodyWeight && detail.bodyWeight.bmi
            ? roundDec(detail.bodyWeight.bmi, 1)
            : ''
        }}
      </div>

      <div class="bodyFat-title">{{ $t('HR001.bodyFat') }}</div>
      <div
        class="bodyFat-value"
        :class="{
          'normal-color': detail?.bodyWeight?.bodyFatWarningLevel == 0,
          'warning-color': detail?.bodyWeight?.bodyFatWarningLevel == 1,
          'abnormal-color': detail?.bodyWeight?.bodyFatWarningLevel == 2,
        }"
      >
        {{
          detail.bodyWeight && detail.bodyWeight.bodyFat
            ? roundDec(detail.bodyWeight.bodyFat, 1)
            : ''
        }}
      </div>
      <div class="bodyFat-unit">%</div>

      <!-- 體脂肪量 -->
      <div class="bodyFatMass-title">{{ $t('HR001.bodyFatMass') }}</div>
      <div class="bodyFatMass-value normal-color">
        {{ getBodyFatMassAvg() }}
      </div>
      <div class="bodyFatMass-unit">{{ getWeightUnit() }}</div>

      <!-- 肌肉率 -->
      <div class="musclePercentage-title">
        {{ $t('HR001.musclePercentage') }}
      </div>
      <div class="musclePercentage-value normal-color">
        {{ getMusclePercentageAvg() }}
      </div>
      <div class="musclePercentage-unit">%</div>

      <!-- 肌肉量 -->
      <div class="muscleMass-title">{{ $t('HR001.muscleMass') }}</div>
      <div class="muscleMass-value normal-color">
        {{ getMuscleMassAvg() }}
      </div>
      <div class="muscleMass-unit">{{ getWeightUnit() }}</div>

      <!-- 內臟脂肪 -->
      <div class="visceralFat-title">{{ $t('HR001.visceralFat') }}</div>
      <div
        class="visceralFat-value"
        :class="{
          'normal-color': detail?.bodyWeight?.visceralFatWarningLevel == 0,
          'warning-color': detail?.bodyWeight?.visceralFatWarningLevel == 1,
          'abnormal-color': detail?.bodyWeight?.visceralFatWarningLevel == 2,
        }"
      >
        {{
          detail.bodyWeight && detail.bodyWeight.visceralFat
            ? Math.floor(detail.bodyWeight.visceralFat)
            : ''
        }}
      </div>
      <div class="visceralFat-unit">{{ $t('HR001.visceralFatUnit') }}</div>

      <!-- 骨骼肌率 -->
      <div class="skeletalMuscleMassPercentage-title">
        {{ $t('HR001.skeletalMuscleMassPercentage') }}
      </div>
      <div class="skeletalMuscleMassPercentage-value normal-color">
        {{ getSkeletalMuscleMassPercentageAvg() }}
      </div>
      <div class="skeletalMuscleMassPercentage-unit">%</div>

      <!-- 水份率 -->
      <div class="bodyWater-title">
        {{ $t('HR001.bodyWater') }}
      </div>
      <div class="bodyWater-value normal-color">
        {{ getBodyWaterAvg() }}
      </div>
      <div class="bodyWater-unit">%</div>

      <!-- 基礎代謝率 -->
      <div class="bmr-title">{{ $t('HR001.bmr') }}</div>
      <div class="bmr-value normal-color">
        {{
          detail.bodyWeight && detail.bodyWeight.bmr
            ? roundDec(detail.bodyWeight.bmr, 0)
            : ''
        }}
      </div>
      <div class="bmr-unit">{{ $t('HR001.bmrUnit') }}</div>

      <!-- 骨量 -->
      <div class="bone-title">{{ $t('HR001.bone') }}</div>
      <div class="bone-value normal-color">
        {{ getBoneAvg() }}
      </div>
      <div class="bone-unit">{{ getWeightUnit() }}</div>

      <div
        class="split-line"
        v-if="detail.bodyWeight && detail.bodyWeight.description"
      ></div>
      <div class="comment">
        {{
          detail.bodyWeight && detail.bodyWeight.description
            ? translateDescription(detail.bodyWeight.description)
            : ''
        }}
      </div>
    </div>
    <div class="area-3 spo2">
      <div class="measure-icon"></div>
      <div class="count-title">{{ $t('HR001.times') }}</div>
      <div class="count">
        {{ detail.spo2 && detail.spo2.times ? detail.spo2.times : '' }}
      </div>

      <div class="spo2-title">{{ $t('HR001.spo2') }}</div>
      <div
        class="spo2-value"
        :class="{
          'normal-color': detail?.spo2?.spo2WarningLevel == 0,
          'warning-color': detail?.spo2?.spo2WarningLevel == 1,
          'abnormal-color': detail?.spo2?.spo2WarningLevel == 2,
        }"
      >
        {{
          detail.spo2 && detail.spo2.value ? roundDec(detail.spo2.value, 0) : ''
        }}
      </div>
      <div class="spo2-unit">%</div>

      <div class="act-title">{{ $t('HR001.act') }}</div>
      <div
        class="act-value"
        :class="{
          'normal-color': detail?.spo2?.actWarningLevel == 0,
          'warning-color': detail?.spo2?.actWarningLevel == 1,
          'abnormal-color': detail?.spo2?.actWarningLevel == 2,
        }"
      >
        {{ detail.spo2 && detail.spo2.act ? roundDec(detail.spo2.act, 0) : '' }}
      </div>

      <div
        class="split-line"
        v-if="detail.spo2 && detail.spo2.description"
      ></div>
      <div class="comment">
        {{
          detail.spo2 && detail.spo2.description
            ? translateDescription(detail.spo2.description)
            : ''
        }}
      </div>
    </div>
    <div class="area-4 temperature">
      <div class="measure-icon"></div>
      <div class="count-title">{{ $t('HR001.times') }}</div>
      <div class="tmp-title">{{ $t('HR001.bodyTemperature') }}</div>
      <div class="count">
        {{
          detail.bodyTemperature && detail.bodyTemperature.times
            ? detail.bodyTemperature.times
            : ''
        }}
      </div>
      <div
        class="tmp"
        :class="{
          'normal-color':
            detail?.bodyTemperature?.bodyTemperatureWarningLevel == 0,
          'warning-color':
            detail?.bodyTemperature?.bodyTemperatureWarningLevel == 1,
          'abnormal-color':
            detail?.bodyTemperature?.bodyTemperatureWarningLevel == 2,
        }"
      >
        {{
          detail.bodyTemperature && detail.bodyTemperature.value
            ? roundDec(detail.bodyTemperature.value, 1)
            : ''
        }}
      </div>
      <div class="unit">{{ getTemperatureUnit() }}</div>
      <div
        class="split-line"
        v-if="detail.bodyTemperature && detail.bodyTemperature.description"
      ></div>
      <div class="comment">
        {{
          detail.bodyTemperature && detail.bodyTemperature.description
            ? translateDescription(detail.bodyTemperature.description)
            : ''
        }}
      </div>
    </div>
  </div>
</template>
<script>
  //import { BarsOutlined } from '@ant-design/icons-vue'
  //import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'
  import { roundDecimal } from '@/utils/pocketKnives'
  import {
    systolicLevel,
    diastolicLevel,
    ppLevel,
    pulseLevel,
    fastingLevel,
    beforeMealLevel,
    afterMealLevel,
    spo2Level,
    actLevel,
    bodyTemperatureLevel,
    bmiLevel,
    visceralFatLevel,
  } from '@/utils/pocketKnives'

  export default {
    data() {
      return {
        systolicLevel: 0,
        diastolicLevel: 0,
        ppLevel: 0,
        pulseLevel: 0,
        fastingLevel: 0,
        beforeMealLevel: 0,
        afterMealLevel: 0,
        spo2Level: 0,
        actLevel: 0,
        bodyTemperatureLevel: 0,
        bmiLevel: 0,
        visceralFatLevel: 0,
      }
    },
    props: {
      detail: {
        type: Object,
        required: true,
        //default: {},
      },
    },
    components: {},
    computed: {
      ...mapGetters({
        measureThreshold: 'measureThreshold/measureThreshold',
      }),
    },
    mounted() {
      this.calLevel()
    },
    methods: {
      roundDec(val, precision) {
        return roundDecimal(val, precision)
      },
      calLevel() {
        this.systolicLevel = systolicLevel(
          Math.round(this.detail?.bloodPressure?.systolic)
        )
        this.diastolicLevel = diastolicLevel(
          Math.round(this.detail?.bloodPressure?.diastolic)
        )
        this.ppLevel = ppLevel(Math.round(this.detail?.bloodPressure?.pp))
        this.pulseLevel = pulseLevel(
          Math.round(this.detail?.bloodPressure?.pulse)
        )
        this.fastingLevel = fastingLevel(
          Math.round(this.detail?.glucose?.fasting)
        )
        this.beforeMealLevel = beforeMealLevel(
          Math.round(this.detail?.glucose?.beforeMeal)
        )
        this.afterMealLevel = afterMealLevel(
          Math.round(this.detail?.glucose?.afterMeal)
        )

        this.spo2Level = spo2Level(Math.round(this.detail?.spo2?.value))
        this.actLevel = actLevel(this.roundDec(this.detail?.spo2?.act, 0))
        this.bodyTemperatureLevel = bodyTemperatureLevel(
          this.roundDec(this.detail?.bodyTemperature?.value, 1)
        )
        this.bmiLevel = bmiLevel(this.roundDec(this.detail?.bodyWeight?.bmi, 1))
        this.visceralFatLevel = visceralFatLevel(
          this.roundDec(this.detail?.bodyWeight?.visceralFat, 1)
        )
      },
      // translateDescription(desc) {
      //   if (!desc) return desc
      //   const strArray = desc.split(',')
      //   let newDesc = ''
      //   strArray.forEach((aDesc) => {
      //     const keyStr = aDesc.split(' ').join('')
      //     if (newDesc !== '') {
      //       // newDesc += ', '
      //       newDesc += ',\n'
      //     }
      //     newDesc += this.$t('MeasurementWarning.' + keyStr)
      //   })
      //   return newDesc
      // },
      translateDescription(desc) {
        if (!desc) return desc
        const strArray = desc.split(',')
        let descArray = []
        strArray.forEach((aDesc) => {
          const keyStr = aDesc.split(' ').join('')
          descArray.push(this.$t('MeasurementWarning.' + keyStr))
        })
        descArray.sort((aStr, bStr) => {
          return aStr.length - bStr.length
        })
        let newDesc = descArray.join(',\n')
        return newDesc
      },
      getTemperatureUnit() {
        if (this.measureThreshold?.temperature?.length > 0) {
          // 公制
          return this.measureThreshold?.temperature[0].unit || ''
        } else {
          return this.measureThreshold?.temperature?.unit || ''
        }
      },
      getWeightUnit() {
        if (this.measureThreshold?.bodyWeight?.unit === 'lbs') {
          return this.$t('MS004.lbs')
        } else {
          // 公制
          return this.$t('MS004.kg')
        }
      },
      getGlucoseUnit() {
        if (this.measureThreshold?.bloodGlucose?.unit === 'mmol/L') {
          return this.$t('MS002.mmol/L')
        } else {
          // 公制
          return this.$t('MS002.mg/dL')
        }
      },
      getSystolicAVG() {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodPressure?.unit === 'kPa') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return this.detail.bloodPressure && this.detail.bloodPressure.systolic
          ? roundDecimal(this.detail.bloodPressure.systolic, roundDecimalParam)
          : ''
      },
      getDiastolicAVG() {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodPressure?.unit === 'kPa') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return this.detail.bloodPressure && this.detail.bloodPressure.diastolic
          ? roundDecimal(this.detail.bloodPressure.diastolic, roundDecimalParam)
          : ''
      },
      getPpAVG() {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodPressure?.unit === 'kPa') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return this.detail.bloodPressure && this.detail.bloodPressure.pp
          ? roundDecimal(this.detail.bloodPressure.pp, roundDecimalParam)
          : ''
      },
      getFastingAVG() {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodGlucose?.unit === 'mmol/L') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return this.detail.glucose && this.detail.glucose.fasting
          ? roundDecimal(this.detail.glucose.fasting, roundDecimalParam)
          : ''
      },
      getBeforeMealAVG() {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodGlucose?.unit === 'mmol/L') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return this.detail.glucose && this.detail.glucose.beforeMeal
          ? roundDecimal(this.detail.glucose.beforeMeal, roundDecimalParam)
          : ''
      },
      getAfterMealAVG() {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodGlucose?.unit === 'mmol/L') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return this.detail.glucose && this.detail.glucose.afterMeal
          ? roundDecimal(this.detail.glucose.afterMeal, roundDecimalParam)
          : ''
      },
      getMuscleMassAvg() {
        // if (
        //   !this.detail?.bodyWeight?.muscleMass ||
        //   !this.detail?.bodyWeight?.value
        // ) {
        //   return ''
        // }
        // return this.roundDec(
        //   (this.detail.bodyWeight.muscleMass / this.detail?.bodyWeight?.value) *
        //     100,
        //   1
        // )

        // return this.detail?.bodyWeight?.skeletalMuscleMass
        //   ? roundDecimal(this.detail?.bodyWeight?.skeletalMuscleMass, 1)
        //   : ''
        return this.detail?.bodyWeight?.muscleMass
          ? roundDecimal(this.detail?.bodyWeight?.muscleMass, 1)
          : ''
      },
      getMusclePercentageAvg() {
        if (
          !this.detail?.bodyWeight?.muscleMass ||
          !this.detail?.bodyWeight?.value
        ) {
          return ''
        }
        return this.roundDec(
          (this.detail.bodyWeight.muscleMass / this.detail?.bodyWeight?.value) *
            100,
          1
        )
      },
      getSkeletalMuscleMassPercentageAvg() {
        return this.detail?.bodyWeight?.skeletalMuscleMassPercentage
          ? roundDecimal(
              this.detail?.bodyWeight?.skeletalMuscleMassPercentage,
              1
            )
          : ''
      },
      getBodyWaterAvg() {
        return this.detail?.bodyWeight?.bodyWater
          ? roundDecimal(this.detail?.bodyWeight?.bodyWater, 1)
          : ''
      },
      getBoneAvg() {
        return this.detail?.bodyWeight?.bone
          ? roundDecimal(this.detail?.bodyWeight?.bone, 1)
          : ''
      },
      getBodyFatMassAvg() {
        if (
          !this.detail?.bodyWeight?.bodyFat ||
          !this.detail?.bodyWeight?.value
        ) {
          return ''
        }
        const bodyFatMass =
          (this.detail?.bodyWeight?.bodyFat * this.detail?.bodyWeight?.value) /
          100
        return roundDecimal(bodyFatMass, 1)
      },
    },
    watch: {
      detail: function () {
        this.calLevel()
      },
    },
  }
</script>
<style lang="less" scoped>
  .normal-color {
    color: #575756;
  }
  .warning-color {
    color: #ffb600;
  }
  .abnormal-color {
    color: #ff0000;
  }
  .measurement-detail-title () {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    /* or 100% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color: #000000;
  }
  .measurement-detail-value () {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .measurement-detail-unit () {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: #c4c4c4;
  }
  .arr-title-font() {
    ont-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
  }
  .arr-value-font() {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #8b2331;
  }
  .measurement-detail-container {
    background-color: #f5f5f5;
    height: 617px;
    padding-top: 15px;
    //position: relative;

    .area-1 {
      width: 930px;
      height: 90px;
      //margin-top: 15px;
      margin-left: 29px;
      background-color: #ffffff;
      box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
      border-radius: 8px;
      position: relative;

      .measure-icon {
        width: 105px;
        height: 90px;
        top: 0px;
        left: 0px;
        position: absolute;
        background-color: #f7f6f6;
        background-image: url('~@/assets/image/area-1-bloodpressure.png');
        background-repeat: no-repeat;
        background-position: center;
      }

      .count-title {
        width: 105px;
        height: 17px;
        top: 12px;
        left: 114px;
        position: absolute;
        .measurement-detail-title ();
      }

      .count {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 139px;
        position: absolute;
        .measurement-detail-value();
      }

      .split-line {
        width: 4px;
        height: 20px;
        top: 35px;
        left: 764px;
        position: absolute;
        background: #333333;
        border-radius: 10px;
      }

      .comment {
        width: 155px;
        height: 17px;
        top: 35px;
        left: 773px;
        position: absolute;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        /* or 89% */

        display: flex;
        //justify-content: center;
        align-items: center;
        //text-align: center;
        color: #333333;
        white-space: pre-line;
      }

      .diastolic-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 217px;
        position: absolute;
        .measurement-detail-title ();
      }

      .diastolic-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 218px;
        position: absolute;
        .measurement-detail-value ();
      }

      .diastolic-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 216px;
        position: absolute;
        .measurement-detail-unit ();
      }

      .systolic-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 295px;
        position: absolute;
        .measurement-detail-title ();
      }

      .systolic-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 295px;
        position: absolute;
        .measurement-detail-value ();
      }

      .systolic-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 216px;
        position: absolute;
        .measurement-detail-unit ();
      }

      .pp-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 371px;
        position: absolute;
        .measurement-detail-title ();
      }

      .pp-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 372px;
        position: absolute;
        .measurement-detail-value ();
      }

      .pp-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 369px;
        position: absolute;
        .measurement-detail-unit ();
      }

      .pulse-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 446px;
        position: absolute;
        .measurement-detail-title ();
      }

      .pulse-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 447px;
        position: absolute;
        .measurement-detail-value ();
      }

      .pulse-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 444px;
        position: absolute;
        .measurement-detail-unit ();
      }

      .arr-title {
        width: 34px;
        height: 16px;
        top: 7px;
        left: 521px;
        position: absolute;
        .arr-title-font();
      }

      .arr-value {
        width: 90px;
        height: 16px;
        top: 7px;
        left: 639px;
        position: absolute;
        .arr-value-font();
      }

      .arr-afib-title {
        width: 71px;
        height: 16px;
        top: 27px;
        left: 521px;
        position: absolute;
        .arr-title-font();
      }

      .arr-afib-value {
        width: 90px;
        height: 16px;
        top: 27px;
        left: 639px;
        position: absolute;
        .arr-value-font();
      }

      .arr-pc-title {
        width: 61px;
        height: 16px;
        top: 47px;
        left: 521px;
        position: absolute;
        .arr-title-font();
      }

      .arr-pc-value {
        width: 90px;
        height: 16px;
        top: 47px;
        left: 639px;
        position: absolute;
        .arr-value-font();
      }

      .arr-afib-pc-title {
        width: 97px;
        height: 16px;
        top: 67px;
        left: 521px;
        position: absolute;
        .arr-title-font();
      }

      .arr-afib-pc-value {
        width: 90px;
        height: 16px;
        top: 67px;
        left: 639px;
        position: absolute;
        .arr-value-font();
      }
    }

    .area-2 {
      width: 930px;
      height: 90px;
      margin-top: 10px;
      margin-left: 29px;
      background-color: #ffffff;
      box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
      border-radius: 8px;
      position: relative;

      .measure-icon {
        width: 105px;
        height: 90px;
        top: 0px;
        left: 0px;
        position: absolute;
        background-color: #f7f6f6;
        background-image: url('~@/assets/image/area-2-glucose.png');
        background-repeat: no-repeat;
        background-position: center;
      }

      .count-title {
        width: 105px;
        height: 17px;
        top: 12px;
        left: 114px;
        position: absolute;
        .measurement-detail-title ();
      }

      .count {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 139px;
        position: absolute;
        .measurement-detail-value ();
      }

      .split-line {
        width: 4px;
        height: 20px;
        top: 35px;
        left: 764px;
        position: absolute;
        background: #333333;
        border-radius: 10px;
      }

      .comment {
        width: 155px;
        height: 17px;
        top: 35px;
        left: 773px;
        position: absolute;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        /* or 89% */

        display: flex;
        //justify-content: center;
        align-items: center;
        //text-align: center;

        color: #333333;
        white-space: pre-line;
      }

      .fasting-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 217px;
        position: absolute;
        .measurement-detail-title ();
      }

      .fasting-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 218px;
        position: absolute;
        .measurement-detail-value ();
      }

      .fasting-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 216px;
        position: absolute;
        .measurement-detail-unit ();
      }

      .beforeMeal-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 295px;
        position: absolute;
        .measurement-detail-title ();
      }

      .beforeMeal-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 295px;
        position: absolute;
        .measurement-detail-value ();
      }

      .beforeMeal-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 297px;
        position: absolute;
        .measurement-detail-unit ();
      }

      .afterMeal-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 382px;
        position: absolute;
        .measurement-detail-title ();
      }

      .afterMeal-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 382px;
        position: absolute;
        .measurement-detail-value ();
      }

      .afterMeal-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 379px;
        position: absolute;
        .measurement-detail-unit ();
      }
    }

    .area-3 {
      width: 930px;
      height: 90px;
      margin-top: 10px;
      margin-left: 29px;
      background-color: #ffffff;
      box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
      border-radius: 8px;
      position: relative;

      .measure-icon {
        width: 105px;
        height: 90px;
        top: 0px;
        left: 0px;
        position: absolute;
        background-color: #f7f6f6;
        background-image: url('~@/assets/image/area-3-spo2.png');
        background-repeat: no-repeat;
        background-position: center;
      }

      .count-title {
        width: 105px;
        height: 17px;
        top: 12px;
        left: 114px;
        position: absolute;
        .measurement-detail-title ();
      }

      .count {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 139px;
        position: absolute;
        .measurement-detail-value ();
      }

      .split-line {
        width: 4px;
        height: 20px;
        top: 35px;
        left: 764px;
        position: absolute;
        background: #333333;
        border-radius: 10px;
      }

      .comment {
        width: 155px;
        height: 17px;
        top: 35px;
        left: 773px;
        position: absolute;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        /* or 89% */

        display: flex;
        //justify-content: center;
        align-items: center;
        //text-align: center;

        color: #333333;
        white-space: pre-line;
      }

      .spo2-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 217px;
        position: absolute;
        .measurement-detail-title ();
      }

      .spo2-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 218px;
        position: absolute;
        .measurement-detail-value ();
      }

      .spo2-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 216px;
        position: absolute;
        .measurement-detail-unit ();
      }

      .act-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 295px;
        position: absolute;
        .measurement-detail-title ();
      }

      .act-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 295px;
        position: absolute;
        .measurement-detail-value ();
      }
    }

    .area-4 {
      width: 930px;
      height: 90px;
      margin-top: 10px;
      margin-left: 29px;
      background-color: #ffffff;
      box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
      border-radius: 8px;
      position: relative;

      .measure-icon {
        width: 105px;
        height: 90px;
        top: 0px;
        left: 0px;
        position: absolute;
        background-color: #f7f6f6;
        background-image: url('~@/assets/image/area-4-temperature.png');
        background-repeat: no-repeat;
        background-position: center;
      }

      .count-title {
        width: 105px;
        height: 17px;
        top: 12px;
        left: 114px;
        position: absolute;
        .measurement-detail-title ();
      }
      .tmp-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 218px;
        position: absolute;
        .measurement-detail-title ();
      }
      .count {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 139px;
        position: absolute;
        .measurement-detail-value ();
      }
      .tmp {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 219px;
        position: absolute;
        .measurement-detail-value ();
      }
      .unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 216px;
        position: absolute;
        .measurement-detail-unit ();
      }

      .split-line {
        width: 4px;
        height: 20px;
        top: 35px;
        left: 764px;
        position: absolute;
        background: #333333;
        border-radius: 10px;
      }

      .comment {
        width: 155px;
        height: 17px;
        top: 35px;
        left: 773px;
        position: absolute;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        /* or 89% */

        display: flex;
        //justify-content: center;
        align-items: center;
        //text-align: center;

        color: #333333;
        white-space: pre-line;
      }
    }

    .area-5 {
      width: 930px;
      height: 180px;
      margin-top: 10px;
      margin-left: 29px;
      background-color: #ffffff;
      box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
      border-radius: 8px;
      position: relative;

      .measure-icon {
        width: 105px;
        height: 180px;
        top: 0px;
        left: 0px;
        position: absolute;
        background-color: #f7f6f6;
        background-image: url('~@/assets/image/area-5-weight.png');
        background-repeat: no-repeat;
        background-position: center;
      }

      .count-title {
        width: 105px;
        height: 17px;
        top: 56px;
        left: 114px;
        position: absolute;
        .measurement-detail-title ();
      }

      .count {
        width: 55px;
        height: 27px;
        top: 78px;
        left: 139px;
        position: absolute;
        .measurement-detail-value ();
      }

      .split-line {
        width: 4px;
        height: 130px;
        top: 20px;
        left: 764px;
        position: absolute;
        background: #333333;
        border-radius: 10px;
      }

      .comment {
        width: 155px;
        height: 17px;
        top: 35px;
        left: 773px;
        position: absolute;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        /* or 89% */

        display: flex;
        //justify-content: center;
        align-items: center;
        //text-align: center;

        color: #333333;
        white-space: pre-line;
      }

      .weight-title {
        width: 55px;
        height: 17px;
        top: 56px;
        left: 217px;
        position: absolute;
        .measurement-detail-title ();
      }

      .weight-value {
        width: 55px;
        height: 27px;
        top: 78px;
        left: 218px;
        position: absolute;
        .measurement-detail-value ();
      }

      .weight-unit {
        width: 60px;
        height: 16px;
        top: 109px;
        left: 216px;
        position: absolute;
        .measurement-detail-unit ();
      }

      .bmi-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 295px;
        position: absolute;
        .measurement-detail-title ();
      }

      .bmi-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 295px;
        position: absolute;
        .measurement-detail-value ();
      }

      // 體脂率
      .bodyFat-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 375px;
        position: absolute;
        .measurement-detail-title ();
      }

      .bodyFat-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 375px;
        position: absolute;
        .measurement-detail-value ();
      }

      .bodyFat-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 375px;
        position: absolute;
        .measurement-detail-unit ();
      }

      // 體脂肪量
      .bodyFatMass-title {
        width: 71px;
        height: 17px;
        top: 12px;
        left: 450px;
        position: absolute;
        .measurement-detail-title ();
      }

      .bodyFatMass-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 450px;
        position: absolute;
        .measurement-detail-value ();
      }

      .bodyFatMass-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 450px;
        position: absolute;
        .measurement-detail-unit ();
      }

      // 肌肉率
      .musclePercentage-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 546px;
        position: absolute;
        .measurement-detail-title ();
      }

      .musclePercentage-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 546px;
        position: absolute;
        .measurement-detail-value ();
      }

      .musclePercentage-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 546px;
        position: absolute;
        .measurement-detail-unit ();
      }

      // 肌肉率
      .muscleMass-title {
        width: 55px;
        height: 17px;
        top: 12px;
        left: 628px;
        position: absolute;
        .measurement-detail-title ();
      }

      .muscleMass-value {
        width: 55px;
        height: 27px;
        top: 34px;
        left: 628px;
        position: absolute;
        .measurement-detail-value ();
      }

      .muscleMass-unit {
        width: 60px;
        height: 16px;
        top: 65px;
        left: 628px;
        position: absolute;
        .measurement-detail-unit ();
      }

      // 內臟脂肪
      .visceralFat-title {
        width: 73px;
        height: 17px;
        top: 102px;
        left: 288px;
        position: absolute;
        .measurement-detail-title ();
      }

      .visceralFat-value {
        width: 55px;
        height: 27px;
        top: 124px;
        left: 288px;
        position: absolute;
        .measurement-detail-value ();
      }

      .visceralFat-unit {
        width: 60px;
        height: 16px;
        top: 155px;
        left: 288px;
        position: absolute;
        .measurement-detail-unit ();
      }

      // 骨骼肌率
      .skeletalMuscleMassPercentage-title {
        width: 73px;
        height: 17px;
        top: 102px;
        left: 370px;
        position: absolute;
        .measurement-detail-title ();
      }

      .skeletalMuscleMassPercentage-value {
        width: 55px;
        height: 27px;
        top: 124px;
        left: 370px;
        position: absolute;
        .measurement-detail-value ();
      }

      .skeletalMuscleMassPercentage-unit {
        width: 60px;
        height: 16px;
        top: 155px;
        left: 370px;
        position: absolute;
        .measurement-detail-unit ();
      }

      // 水分率
      .bodyWater-title {
        width: 73px;
        height: 17px;
        top: 102px;
        left: 445px;
        position: absolute;
        .measurement-detail-title ();
      }

      .bodyWater-value {
        width: 55px;
        height: 27px;
        top: 124px;
        left: 450px;
        position: absolute;
        .measurement-detail-value ();
      }

      .bodyWater-unit {
        width: 60px;
        height: 16px;
        top: 155px;
        left: 445px;
        position: absolute;
        .measurement-detail-unit ();
      }

      // 基礎代謝率
      .bmr-title {
        width: 105px;
        height: 17px;
        top: 102px;
        left: 529px;
        position: absolute;
        .measurement-detail-title ();
      }

      .bmr-value {
        width: 55px;
        height: 27px;
        top: 124px;
        left: 546px;
        position: absolute;
        .measurement-detail-value ();
      }

      .bmr-unit {
        width: 60px;
        height: 16px;
        top: 155px;
        left: 546px;
        position: absolute;
        .measurement-detail-unit ();
      }

      // 骨量
      .bone-title {
        width: 50px;
        height: 17px;
        top: 102px;
        left: 631px;
        position: absolute;
        .measurement-detail-title ();
      }

      .bone-value {
        width: 55px;
        height: 27px;
        top: 124px;
        left: 631px;
        position: absolute;
        .measurement-detail-value ();
      }

      .bone-unit {
        width: 60px;
        height: 16px;
        top: 155px;
        left: 631px;
        position: absolute;
        .measurement-detail-unit ();
      }
    }
  }
</style>
